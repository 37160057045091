export const HOST_INCREMENT_ROUND = "host/incrementRound";
export const HOST_SELECT_ICON = "host/selectIcon";
export const HOST_COUNTDOWN_TICK = "host/countDownTick";
export const HOST_CLEAR_BOARD = "host/clearBoard";
export const HOST_VISIT_LOBBY = "host/visitLobby";
export const HOST_MODIFY_SETTINGS = "host/modifyGameSettings";
export const HOST_SUBMIT_PLAYER_NAME = "host/submitPlayerName";
export const HOST_PEER_START = "host/peerStart";
export const HOST_PEER_STOP = "host/peerStop";
export const HOST_PEER_FORCED_DC = "host/peerForcedDisconnect";
