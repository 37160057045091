export const heroList = [
  { name: "Bulbasaur", url: "bulbasaur.png" },
  { name: "Ivysaur", url: "ivysaur.png" },
  { name: "Venusaur", url: "venusaur.png" },
  { name: "Charmander", url: "charmander.png" },
  { name: "Charmeleon", url: "charmeleon.png" },
  { name: "Charizard", url: "charizard.png" },
  { name: "Squirtle", url: "squirtle.png" },
  { name: "Wartortle", url: "wartortle.png" },
  { name: "Blastoise", url: "blastoise.png" },
  { name: "Caterpie", url: "caterpie.png" },
  { name: "Metapod", url: "metapod.png" },
  { name: "Butterfree", url: "butterfree.png" },
  { name: "Weedle", url: "weedle.png" },
  { name: "Kakuna", url: "kakuna.png" },
  { name: "Beedrill", url: "beedrill.png" },
  { name: "Pidgey", url: "pidgey.png" },
  { name: "Pidgeotto", url: "pidgeotto.png" },
  { name: "Pidgeot", url: "pidgeot.png" },
  { name: "Rattata", url: "rattata.png" },
  { name: "Raticate", url: "raticate.png" },
  { name: "Spearow", url: "spearow.png" },
  { name: "Fearow", url: "fearow.png" },
  { name: "Ekans", url: "ekans.png" },
  { name: "Arbok", url: "arbok.png" },
  { name: "Pikachu", url: "pikachu.png" },
  { name: "Raichu", url: "raichu.png" },
  { name: "Sandshrew", url: "sandshrew.png" },
  { name: "Sandslash", url: "sandslash.png" },
  { name: "Nidoran-f", url: "nidoran-f.png" },
  { name: "Nidorina", url: "nidorina.png" },
  { name: "Nidoqueen", url: "nidoqueen.png" },
  { name: "Nidoran-m", url: "nidoran-m.png" },
  { name: "Nidorino", url: "nidorino.png" },
  { name: "Nidoking", url: "nidoking.png" },
  { name: "Clefairy", url: "clefairy.png" },
  { name: "Clefable", url: "clefable.png" },
  { name: "Vulpix", url: "vulpix.png" },
  { name: "Ninetales", url: "ninetales.png" },
  { name: "Jigglypuff", url: "jigglypuff.png" },
  { name: "Wigglytuff", url: "wigglytuff.png" },
  { name: "Zubat", url: "zubat.png" },
  { name: "Golbat", url: "golbat.png" },
  { name: "Oddish", url: "oddish.png" },
  { name: "Gloom", url: "gloom.png" },
  { name: "Vileplume", url: "vileplume.png" },
  { name: "Paras", url: "paras.png" },
  { name: "Parasect", url: "parasect.png" },
  { name: "Venonat", url: "venonat.png" },
  { name: "Venomoth", url: "venomoth.png" },
  { name: "Diglett", url: "diglett.png" },
  { name: "Dugtrio", url: "dugtrio.png" },
  { name: "Meowth", url: "meowth.png" },
  { name: "Persian", url: "persian.png" },
  { name: "Psyduck", url: "psyduck.png" },
  { name: "Golduck", url: "golduck.png" },
  { name: "Mankey", url: "mankey.png" },
  { name: "Primeape", url: "primeape.png" },
  { name: "Growlithe", url: "growlithe.png" },
  { name: "Arcanine", url: "arcanine.png" },
  { name: "Poliwag", url: "poliwag.png" },
  { name: "Poliwhirl", url: "poliwhirl.png" },
  { name: "Poliwrath", url: "poliwrath.png" },
  { name: "Abra", url: "abra.png" },
  { name: "Kadabra", url: "kadabra.png" },
  { name: "Alakazam", url: "alakazam.png" },
  { name: "Machop", url: "machop.png" },
  { name: "Machoke", url: "machoke.png" },
  { name: "Machamp", url: "machamp.png" },
  { name: "Bellsprout", url: "bellsprout.png" },
  { name: "Weepinbell", url: "weepinbell.png" },
  { name: "Victreebel", url: "victreebel.png" },
  { name: "Tentacool", url: "tentacool.png" },
  { name: "Tentacruel", url: "tentacruel.png" },
  { name: "Geodude", url: "geodude.png" },
  { name: "Graveler", url: "graveler.png" },
  { name: "Golem", url: "golem.png" },
  { name: "Ponyta", url: "ponyta.png" },
  { name: "Rapidash", url: "rapidash.png" },
  { name: "Slowpoke", url: "slowpoke.png" },
  { name: "Slowbro", url: "slowbro.png" },
  { name: "Magnemite", url: "magnemite.png" },
  { name: "Magneton", url: "magneton.png" },
  { name: "Farfetchd", url: "farfetchd.png" },
  { name: "Doduo", url: "doduo.png" },
  { name: "Dodrio", url: "dodrio.png" },
  { name: "Seel", url: "seel.png" },
  { name: "Dewgong", url: "dewgong.png" },
  { name: "Grimer", url: "grimer.png" },
  { name: "Muk", url: "muk.png" },
  { name: "Shellder", url: "shellder.png" },
  { name: "Cloyster", url: "cloyster.png" },
  { name: "Gastly", url: "gastly.png" },
  { name: "Haunter", url: "haunter.png" },
  { name: "Gengar", url: "gengar.png" },
  { name: "Onix", url: "onix.png" },
  { name: "Drowzee", url: "drowzee.png" },
  { name: "Hypno", url: "hypno.png" },
  { name: "Krabby", url: "krabby.png" },
  { name: "Kingler", url: "kingler.png" },
  { name: "Voltorb", url: "voltorb.png" },
  { name: "Electrode", url: "electrode.png" },
  { name: "Exeggcute", url: "exeggcute.png" },
  { name: "Exeggutor", url: "exeggutor.png" },
  { name: "Cubone", url: "cubone.png" },
  { name: "Marowak", url: "marowak.png" },
  { name: "Hitmonlee", url: "hitmonlee.png" },
  { name: "Hitmonchan", url: "hitmonchan.png" },
  { name: "Lickitung", url: "lickitung.png" },
  { name: "Koffing", url: "koffing.png" },
  { name: "Weezing", url: "weezing.png" },
  { name: "Rhyhorn", url: "rhyhorn.png" },
  { name: "Rhydon", url: "rhydon.png" },
  { name: "Chansey", url: "chansey.png" },
  { name: "Tangela", url: "tangela.png" },
  { name: "Kangaskhan", url: "kangaskhan.png" },
  { name: "Horsea", url: "horsea.png" },
  { name: "Seadra", url: "seadra.png" },
  { name: "Goldeen", url: "goldeen.png" },
  { name: "Seaking", url: "seaking.png" },
  { name: "Staryu", url: "staryu.png" },
  { name: "Starmie", url: "starmie.png" },
  { name: "Mr-mime", url: "mr-mime.png" },
  { name: "Scyther", url: "scyther.png" },
  { name: "Jynx", url: "jynx.png" },
  { name: "Electabuzz", url: "electabuzz.png" },
  { name: "Magmar", url: "magmar.png" },
  { name: "Pinsir", url: "pinsir.png" },
  { name: "Tauros", url: "tauros.png" },
  { name: "Magikarp", url: "magikarp.png" },
  { name: "Gyarados", url: "gyarados.png" },
  { name: "Lapras", url: "lapras.png" },
  { name: "Ditto", url: "ditto.png" },
  { name: "Eevee", url: "eevee.png" },
  { name: "Vaporeon", url: "vaporeon.png" },
  { name: "Jolteon", url: "jolteon.png" },
  { name: "Flareon", url: "flareon.png" },
  { name: "Porygon", url: "porygon.png" },
  { name: "Omanyte", url: "omanyte.png" },
  { name: "Omastar", url: "omastar.png" },
  { name: "Kabuto", url: "kabuto.png" },
  { name: "Kabutops", url: "kabutops.png" },
  { name: "Aerodactyl", url: "aerodactyl.png" },
  { name: "Snorlax", url: "snorlax.png" },
  { name: "Articuno", url: "articuno.png" },
  { name: "Zapdos", url: "zapdos.png" },
  { name: "Moltres", url: "moltres.png" },
  { name: "Dratini", url: "dratini.png" },
  { name: "Dragonair", url: "dragonair.png" },
  { name: "Dragonite", url: "dragonite.png" },
  { name: "Mewtwo", url: "mewtwo.png" },
  { name: "Mew", url: "mew.png" },
];
